import { Link } from "@inertiajs/react";

export default function SecondaryButtonLink({
    className = "",
    disabled = false,
    href,
    background = "light",
    children,
    ...props
}) {
    return (
        <Link
            href={href}
            {...props}
            className={
                `inline-flex items-center px-4 py-2 border justify-center
              rounded-3xl font-semibold text-md tracking-widest shadow-xl focus:outline-none focus:ring-2 disabled:opacity-25
              transition ease-in-out duration-150
                ${disabled ? "opacity-50 cursor-not-allowed" : ""}
                ${
                    background == "light" &&
                    "text-astronaut-blue bg-black-white"
                }
                ${
                    background == "dark" &&
                    "border-gray-300 text-alabaster"
                }
                ${
                    background == "light" && !disabled &&
                    "hover:border-red-300 hover:bg-outragous-orange hover:text-white focus:ring-outragous-orange"
                }
                ${
                    background == "dark" && !disabled &&
                    "hover:bg-gray-50 hover:text-black focus:ring-outragous-orange"
                }
              ` + className
            }
            disabled={disabled}
        >
            {children}
        </Link>
    );
}
